/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2HealthScore } from '../model/';
// @ts-ignore
import { C2HealthScoreSingleDto } from '../model/';
// @ts-ignore
import { C2HealthScoreSummaryDto } from '../model/';
// @ts-ignore
import { C2HealthScoreZapColorAggregation } from '../model/';
// @ts-ignore
import { C2IdDateInfo } from '../model/';
// @ts-ignore
import { CreateC2HealthScoreDto } from '../model/';
// @ts-ignore
import { CustomerListFilterDto } from '../model/';
// @ts-ignore
import { DateTypeEnum } from '../model/';
// @ts-ignore
import { PaginatedC2HealthScoreDto } from '../model/';
// @ts-ignore
import { RoleBaseCustomerHealthDto } from '../model/';
// @ts-ignore
import { RoleBaseCustomerPaginatedDto } from '../model/';
// @ts-ignore
import { UpdateC2InfoDto } from '../model/';
/**
 * C2HealthScoreApi - axios parameter creator
 * @export
 */
export const C2HealthScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create C2 Health Score
         * @param {CreateC2HealthScoreDto} createC2HealthScoreDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerCreate: async (createC2HealthScoreDto: CreateC2HealthScoreDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2HealthScoreDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerCreate', 'createC2HealthScoreDto', createC2HealthScoreDto)
            const localVarPath = `/api/c2-health-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2HealthScoreDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download CSV of c2 health score based on filter and sorting
         * @param {string} customViewId Optional field
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerDownloadCustomerInfoCsv: async (customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customViewId' is not null or undefined
            assertParamExists('c2HealthScoreControllerDownloadCustomerInfoCsv', 'customViewId', customViewId)
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerDownloadCustomerInfoCsv', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/c2-health-score/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customViewId !== undefined) {
                localVarQueryParameter['customViewId'] = customViewId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download customer list CSV for default view
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerDownloadCustomerListCsvForDefaultView: async (customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerDownloadCustomerListCsvForDefaultView', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/c2-health-score/downloadDefaultView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2-Health-Score against filter and sorting with pagination 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFilter: async (customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerFilter', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/c2-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFilterV2: async (customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerFilterV2', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/v2/c2-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Version 3 - List of c2 health score against dynamic columns against filter and sorting with pagination
         * @param {string} customViewId 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFilterV3: async (customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customViewId' is not null or undefined
            assertParamExists('c2HealthScoreControllerFilterV3', 'customViewId', customViewId)
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerFilterV3', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/v3/c2-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (customViewId !== undefined) {
                localVarQueryParameter['customViewId'] = customViewId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2-Health-Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-health-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2-Health-Score by date range
         * @param {string} c2Id 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindAllByDateRange: async (c2Id: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2Id' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindAllByDateRange', 'c2Id', c2Id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindAllByDateRange', 'dateType', dateType)
            const localVarPath = `/api/c2-health-score/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2Id !== undefined) {
                localVarQueryParameter['c2_id'] = c2Id;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all c2 app ids group by zapscore color code 
         * @param {DateTypeEnum} type 
         * @param {string} dateUtc 
         * @param {string} date 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {string} [segmentId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindAllZapscoreColorAggregationForDate: async (type: DateTypeEnum, dateUtc: string, date: string, xTenantId?: string, c2TypeId?: string, segmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindAllZapscoreColorAggregationForDate', 'type', type)
            // verify required parameter 'dateUtc' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindAllZapscoreColorAggregationForDate', 'dateUtc', dateUtc)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindAllZapscoreColorAggregationForDate', 'date', date)
            const localVarPath = `/api/c2-health-score/zapscore-color-aggregation-for-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateUtc !== undefined) {
                localVarQueryParameter['dateUtc'] = (dateUtc as any instanceof Date) ?
                    (dateUtc as any).toISOString() :
                    dateUtc;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2_type_id'] = c2TypeId;
            }

            if (segmentId !== undefined) {
                localVarQueryParameter['segment_id'] = segmentId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary C2-Health-Score against id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindOne', 'id', id)
            const localVarPath = `/api/c2-health-score/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2 Health Score by C2Id and DateInfo
         * @param {C2IdDateInfo} c2IdDateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindOneByC2IdAndDayId: async (c2IdDateInfo: C2IdDateInfo, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2IdDateInfo' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindOneByC2IdAndDayId', 'c2IdDateInfo', c2IdDateInfo)
            const localVarPath = `/api/c2-health-score/findByC2IdAndDayId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2IdDateInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary C2-Health-Score against id v2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindOneV2: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2HealthScoreControllerFindOneV2', 'id', id)
            const localVarPath = `/api/v2/c2-health-score/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerGetC2ZapSummary: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2HealthScoreControllerGetC2ZapSummary', 'id', id)
            const localVarPath = `/api/c2-health-score/{id}/zap-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C2 info against id
         * @param {string} id 
         * @param {UpdateC2InfoDto} updateC2InfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerUpdate: async (id: string, updateC2InfoDto: UpdateC2InfoDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2HealthScoreControllerUpdate', 'id', id)
            // verify required parameter 'updateC2InfoDto' is not null or undefined
            assertParamExists('c2HealthScoreControllerUpdate', 'updateC2InfoDto', updateC2InfoDto)
            const localVarPath = `/api/c2-health-score/{id}/update-customer-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2InfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2HealthScoreApi - functional programming interface
 * @export
 */
export const C2HealthScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2HealthScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create C2 Health Score
         * @param {CreateC2HealthScoreDto} createC2HealthScoreDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerCreate(createC2HealthScoreDto: CreateC2HealthScoreDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2HealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerCreate(createC2HealthScoreDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download CSV of c2 health score based on filter and sorting
         * @param {string} customViewId Optional field
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerDownloadCustomerInfoCsv(customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerDownloadCustomerInfoCsv(customViewId, customerListFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download customer list CSV for default view
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerDownloadCustomerListCsvForDefaultView(customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerDownloadCustomerListCsvForDefaultView(customerListFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2-Health-Score against filter and sorting with pagination 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFilter(customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2HealthScoreDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFilter(customerListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFilterV2(customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseCustomerPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFilterV2(customerListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Version 3 - List of c2 health score against dynamic columns against filter and sorting with pagination
         * @param {string} customViewId 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFilterV3(customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseCustomerPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFilterV3(customViewId, customerListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2-Health-Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2HealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2-Health-Score by date range
         * @param {string} c2Id 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFindAllByDateRange(c2Id: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2HealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFindAllByDateRange(c2Id, date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all c2 app ids group by zapscore color code 
         * @param {DateTypeEnum} type 
         * @param {string} dateUtc 
         * @param {string} date 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {string} [segmentId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFindAllZapscoreColorAggregationForDate(type: DateTypeEnum, dateUtc: string, date: string, xTenantId?: string, c2TypeId?: string, segmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2HealthScoreZapColorAggregation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFindAllZapscoreColorAggregationForDate(type, dateUtc, date, xTenantId, c2TypeId, segmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary C2-Health-Score against id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseCustomerHealthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2 Health Score by C2Id and DateInfo
         * @param {C2IdDateInfo} c2IdDateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFindOneByC2IdAndDayId(c2IdDateInfo: C2IdDateInfo, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseCustomerHealthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFindOneByC2IdAndDayId(c2IdDateInfo, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary C2-Health-Score against id v2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerFindOneV2(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2HealthScoreSingleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerFindOneV2(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerGetC2ZapSummary(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2HealthScoreSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerGetC2ZapSummary(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C2 info against id
         * @param {string} id 
         * @param {UpdateC2InfoDto} updateC2InfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2HealthScoreControllerUpdate(id: string, updateC2InfoDto: UpdateC2InfoDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2HealthScoreSingleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2HealthScoreControllerUpdate(id, updateC2InfoDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2HealthScoreApi - factory interface
 * @export
 */
export const C2HealthScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2HealthScoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Create C2 Health Score
         * @param {CreateC2HealthScoreDto} createC2HealthScoreDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerCreate(createC2HealthScoreDto: CreateC2HealthScoreDto, xTenantId?: string, options?: any): AxiosPromise<C2HealthScore> {
            return localVarFp.c2HealthScoreControllerCreate(createC2HealthScoreDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download CSV of c2 health score based on filter and sorting
         * @param {string} customViewId Optional field
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerDownloadCustomerInfoCsv(customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.c2HealthScoreControllerDownloadCustomerInfoCsv(customViewId, customerListFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download customer list CSV for default view
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerDownloadCustomerListCsvForDefaultView(customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.c2HealthScoreControllerDownloadCustomerListCsvForDefaultView(customerListFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2-Health-Score against filter and sorting with pagination 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFilter(customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedC2HealthScoreDto> {
            return localVarFp.c2HealthScoreControllerFilter(customerListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFilterV2(customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseCustomerPaginatedDto> {
            return localVarFp.c2HealthScoreControllerFilterV2(customerListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Version 3 - List of c2 health score against dynamic columns against filter and sorting with pagination
         * @param {string} customViewId 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFilterV3(customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseCustomerPaginatedDto> {
            return localVarFp.c2HealthScoreControllerFilterV3(customViewId, customerListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2-Health-Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C2HealthScore>> {
            return localVarFp.c2HealthScoreControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2-Health-Score by date range
         * @param {string} c2Id 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindAllByDateRange(c2Id: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<C2HealthScore>> {
            return localVarFp.c2HealthScoreControllerFindAllByDateRange(c2Id, date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all c2 app ids group by zapscore color code 
         * @param {DateTypeEnum} type 
         * @param {string} dateUtc 
         * @param {string} date 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {string} [segmentId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindAllZapscoreColorAggregationForDate(type: DateTypeEnum, dateUtc: string, date: string, xTenantId?: string, c2TypeId?: string, segmentId?: string, options?: any): AxiosPromise<Array<C2HealthScoreZapColorAggregation>> {
            return localVarFp.c2HealthScoreControllerFindAllZapscoreColorAggregationForDate(type, dateUtc, date, xTenantId, c2TypeId, segmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary C2-Health-Score against id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<RoleBaseCustomerHealthDto> {
            return localVarFp.c2HealthScoreControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2 Health Score by C2Id and DateInfo
         * @param {C2IdDateInfo} c2IdDateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindOneByC2IdAndDayId(c2IdDateInfo: C2IdDateInfo, xTenantId?: string, options?: any): AxiosPromise<RoleBaseCustomerHealthDto> {
            return localVarFp.c2HealthScoreControllerFindOneByC2IdAndDayId(c2IdDateInfo, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary C2-Health-Score against id v2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerFindOneV2(id: string, xTenantId?: string, options?: any): AxiosPromise<C2HealthScoreSingleDto> {
            return localVarFp.c2HealthScoreControllerFindOneV2(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerGetC2ZapSummary(id: string, xTenantId?: string, options?: any): AxiosPromise<C2HealthScoreSummaryDto> {
            return localVarFp.c2HealthScoreControllerGetC2ZapSummary(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C2 info against id
         * @param {string} id 
         * @param {UpdateC2InfoDto} updateC2InfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2HealthScoreControllerUpdate(id: string, updateC2InfoDto: UpdateC2InfoDto, xTenantId?: string, options?: any): AxiosPromise<C2HealthScoreSingleDto> {
            return localVarFp.c2HealthScoreControllerUpdate(id, updateC2InfoDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2HealthScoreControllerCreate operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerCreateRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerCreateRequest {
    /**
     * 
     * @type {CreateC2HealthScoreDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerCreate
     */
    readonly createC2HealthScoreDto: CreateC2HealthScoreDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerDownloadCustomerInfoCsv operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsvRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsvRequest {
    /**
     * Optional field
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsv
     */
    readonly customViewId: string

    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsv
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for c2HealthScoreControllerDownloadCustomerListCsvForDefaultView operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultViewRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultViewRequest {
    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultView
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultView
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultView
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultView
     */
    readonly descending?: boolean
}

/**
 * Request parameters for c2HealthScoreControllerFilter operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFilterRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerFilterRequest {
    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilter
     */
    readonly after?: string
}

/**
 * Request parameters for c2HealthScoreControllerFilterV2 operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFilterV2Request
 */
export interface C2HealthScoreApiC2HealthScoreControllerFilterV2Request {
    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for c2HealthScoreControllerFilterV3 operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFilterV3Request
 */
export interface C2HealthScoreApiC2HealthScoreControllerFilterV3Request {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly customViewId: string

    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFilterV3
     */
    readonly after?: string
}

/**
 * Request parameters for c2HealthScoreControllerFindAll operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFindAllRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerFindAllByDateRange operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFindAllByDateRangeRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllByDateRange
     */
    readonly c2Id: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllByDateRange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerFindAllZapscoreColorAggregationForDate operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDateRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDateRequest {
    /**
     * 
     * @type {DateTypeEnum}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDate
     */
    readonly type: DateTypeEnum

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDate
     */
    readonly dateUtc: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDate
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDate
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDate
     */
    readonly c2TypeId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDate
     */
    readonly segmentId?: string
}

/**
 * Request parameters for c2HealthScoreControllerFindOne operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFindOneRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerFindOneByC2IdAndDayId operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFindOneByC2IdAndDayIdRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerFindOneByC2IdAndDayIdRequest {
    /**
     * 
     * @type {C2IdDateInfo}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindOneByC2IdAndDayId
     */
    readonly c2IdDateInfo: C2IdDateInfo

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindOneByC2IdAndDayId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerFindOneV2 operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerFindOneV2Request
 */
export interface C2HealthScoreApiC2HealthScoreControllerFindOneV2Request {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindOneV2
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerFindOneV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerGetC2ZapSummary operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerGetC2ZapSummaryRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerGetC2ZapSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerGetC2ZapSummary
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerGetC2ZapSummary
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2HealthScoreControllerUpdate operation in C2HealthScoreApi.
 * @export
 * @interface C2HealthScoreApiC2HealthScoreControllerUpdateRequest
 */
export interface C2HealthScoreApiC2HealthScoreControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2InfoDto}
     * @memberof C2HealthScoreApiC2HealthScoreControllerUpdate
     */
    readonly updateC2InfoDto: UpdateC2InfoDto

    /**
     * 
     * @type {string}
     * @memberof C2HealthScoreApiC2HealthScoreControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * C2HealthScoreApi - object-oriented interface
 * @export
 * @class C2HealthScoreApi
 * @extends {BaseAPI}
 */
export class C2HealthScoreApi extends BaseAPI {
    /**
     * 
     * @summary Create C2 Health Score
     * @param {C2HealthScoreApiC2HealthScoreControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerCreate(requestParameters: C2HealthScoreApiC2HealthScoreControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerCreate(requestParameters.createC2HealthScoreDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download CSV of c2 health score based on filter and sorting
     * @param {C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerDownloadCustomerInfoCsv(requestParameters: C2HealthScoreApiC2HealthScoreControllerDownloadCustomerInfoCsvRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerDownloadCustomerInfoCsv(requestParameters.customViewId, requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download customer list CSV for default view
     * @param {C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerDownloadCustomerListCsvForDefaultView(requestParameters: C2HealthScoreApiC2HealthScoreControllerDownloadCustomerListCsvForDefaultViewRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerDownloadCustomerListCsvForDefaultView(requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2-Health-Score against filter and sorting with pagination 
     * @param {C2HealthScoreApiC2HealthScoreControllerFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFilter(requestParameters: C2HealthScoreApiC2HealthScoreControllerFilterRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFilter(requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
     * @param {C2HealthScoreApiC2HealthScoreControllerFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFilterV2(requestParameters: C2HealthScoreApiC2HealthScoreControllerFilterV2Request, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFilterV2(requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Version 3 - List of c2 health score against dynamic columns against filter and sorting with pagination
     * @param {C2HealthScoreApiC2HealthScoreControllerFilterV3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFilterV3(requestParameters: C2HealthScoreApiC2HealthScoreControllerFilterV3Request, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFilterV3(requestParameters.customViewId, requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2-Health-Score
     * @param {C2HealthScoreApiC2HealthScoreControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFindAll(requestParameters: C2HealthScoreApiC2HealthScoreControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2-Health-Score by date range
     * @param {C2HealthScoreApiC2HealthScoreControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFindAllByDateRange(requestParameters: C2HealthScoreApiC2HealthScoreControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFindAllByDateRange(requestParameters.c2Id, requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all c2 app ids group by zapscore color code 
     * @param {C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFindAllZapscoreColorAggregationForDate(requestParameters: C2HealthScoreApiC2HealthScoreControllerFindAllZapscoreColorAggregationForDateRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFindAllZapscoreColorAggregationForDate(requestParameters.type, requestParameters.dateUtc, requestParameters.date, requestParameters.xTenantId, requestParameters.c2TypeId, requestParameters.segmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary C2-Health-Score against id
     * @param {C2HealthScoreApiC2HealthScoreControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFindOne(requestParameters: C2HealthScoreApiC2HealthScoreControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2 Health Score by C2Id and DateInfo
     * @param {C2HealthScoreApiC2HealthScoreControllerFindOneByC2IdAndDayIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFindOneByC2IdAndDayId(requestParameters: C2HealthScoreApiC2HealthScoreControllerFindOneByC2IdAndDayIdRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFindOneByC2IdAndDayId(requestParameters.c2IdDateInfo, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary C2-Health-Score against id v2
     * @param {C2HealthScoreApiC2HealthScoreControllerFindOneV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerFindOneV2(requestParameters: C2HealthScoreApiC2HealthScoreControllerFindOneV2Request, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerFindOneV2(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C2HealthScoreApiC2HealthScoreControllerGetC2ZapSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerGetC2ZapSummary(requestParameters: C2HealthScoreApiC2HealthScoreControllerGetC2ZapSummaryRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerGetC2ZapSummary(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C2 info against id
     * @param {C2HealthScoreApiC2HealthScoreControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2HealthScoreApi
     */
    public c2HealthScoreControllerUpdate(requestParameters: C2HealthScoreApiC2HealthScoreControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C2HealthScoreApiFp(this.configuration).c2HealthScoreControllerUpdate(requestParameters.id, requestParameters.updateC2InfoDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
